<template>
  <div>
    <el-dialog title="创建用户" :visible.sync="dialogFormVisible" :append-to-body="true" :close-on-click-modal="false" @closed="cancel" width="500px">
      <el-form ref="assignment" :model="assignment" :rules="rules" label-position="right" label-width="84px" v-if="assignment" :disabled="disabled">
        <el-form-item label="登录账号" prop="username">
          <el-input v-model.trim="assignment.username" :maxlength="15" />
        </el-form-item>
        <!-- <el-form-item label="密码" prop="password">
          <el-input v-model="assignment.password" :maxlength="20" />
        </el-form-item>-->
        <el-form-item label="角色" prop="_roles">
          <quick-select v-model="assignment._roles" filterable url="api/roles/all" multiple placeholder="请选择角色" style="width: 100%;" />
        </el-form-item>
        <el-alert type="warning" effect="dark" show-icon :closable="false" title="账号初始密码为：123456，创建后可在账号管理中进行维护。" />
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialogFormVisible = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doAdd">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addByEmpId } from "@/api/user";
import { getAll } from "@/api/role";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      disabled: false,
      assignment: null,
      rules: {
        username: [
          { required: true, message: "姓名为必填项", trigger: "blur" }
        ],
        password: [{ required: true, message: "排序为必填项" }],
        _roles: [
          { required: true, message: "角色为必选项" },
          { type: "array", min: 1, message: "角色为必选项" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.assignment = null;
    },
    // 确认保存
    doAdd() {
      this.$refs["assignment"].validate(valid => {
        if (valid) {
          this.assignment.roles = this.assignment._roles.map(v => {
            return { id: v };
          });
          if(!this.assignment.job.id){
            delete this.assignment.job;
          }
          addByEmpId(this.assignment.empId, this.assignment)
            .then(res => {
              this.dialogFormVisible = false;
              this.$notify({
                title: "创建帐号成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(employee) {
      if (employee) {
        this.dialogFormVisible = true;
        this.disabled = false;
        this.assignment = {
          dept: { id: employee.deptId },
          job: { id: employee.jobId },
          username: null,
          userRealName: employee.name,
          phone: employee.phone,
          email: employee.email,
          enabled: true,
          _roles: [],
          empId: employee.id
        };
      }
    }
  }
};
</script>

